var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticStyle: { "text-align": "center", "padding-bottom": "16px" } },
      [
        _vm.scanInfo.type == "图片"
          ? _c("img", {
              staticStyle: { width: "100%" },
              attrs: { src: _vm.scanInfo.url, alt: "" },
            })
          : _vm.scanInfo.type == "音频"
          ? _c(
              "audio",
              {
                attrs: {
                  src: _vm.scanInfo.url,
                  controls: "",
                  height: "100",
                  width: "100",
                },
              },
              [_vm._v(" 您的浏览器不支持 audio 标签。 ")]
            )
          : _vm._e(),
        _c("h3", { staticStyle: { "padding-bottom": "16px" } }, [
          _vm._v(_vm._s(_vm.scanInfo.name)),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }