<template>
  <el-dialog
    v-if="dialogVisible"
    top="20px"
    title="列表信息"
    :visible.sync="dialogVisible"
    width="40%"
  >
    <el-form
      :model="formInfo"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="作品名称" prop="title">
        <el-input v-model="formInfo.title"></el-input>
      </el-form-item>
      <!-- 预留接口 提供标签选择 -->
      <!-- <el-form-item label="所属标签" prop="label">
          <el-select v-model="formInfo.label" style="width:100%s" placeholder="请选择所属标签">
            <el-option label="游戏" value="yx"></el-option>
            <el-option label="环保" value="hb"></el-option>
            <el-option label="编程" value="bc"></el-option>
          </el-select>
        </el-form-item> -->
      <el-form-item label="作品简介" prop="description">
        <el-input type="textarea" v-model="formInfo.description"></el-input>
      </el-form-item>
      <el-form-item style='text-align:center;padding-right:50px'>
        <el-button  @click="dialogVisible=false">取消</el-button>
        <el-button type="primary" @click="saveFile">提交</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
export default {
  props: {
    formInfos: {
      type: Object,
      default() {
        return {};
      },
    },
    rules: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  created() {
    this.formInfo = { ...this.formInfos };
  },
  watch: {
    formInfos: {
      handler(val) {
        this.formInfo = { ...this.formInfos };
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
      formInfo: {},
    };
  },
  methods: {
    saveFile() {
      this.$emit("saveFile", this.formInfo);
    },
    open() {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
  },
};
</script>