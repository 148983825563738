var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "ruleForm",
      staticClass: "demo-ruleForm",
      attrs: { model: _vm.formInfo, rules: _vm.rules, "label-width": "100px" },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "作品名称", prop: "firstName" } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c("el-input", {
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.flash.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.formInfo.firstName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInfo, "firstName", $$v)
                      },
                      expression: "formInfo.firstName",
                    },
                  }),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 2 } }, [
                _c("span", { staticStyle: { "margin-left": "8px" } }, [
                  _vm._v(" ." + _vm._s(_vm.formInfo.lastName) + " "),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { staticStyle: { "text-align": "center", "padding-right": "50px" } },
        [
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveFile } },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }