<template>
  <el-form
    :model="formInfo"
    :rules="rules"
    ref="ruleForm"
    label-width="100px"
    class="demo-ruleForm"
  >
    <el-form-item label="作品名称" prop="firstName">
      <el-row>
        <el-col :span="20">
          <el-input  @keyup.enter='flash' v-model="formInfo.firstName"> </el-input>
        </el-col>
        <el-col :span="2">
          <span style="margin-left: 8px"> .{{ formInfo.lastName }} </span>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item style="text-align: center; padding-right: 50px">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="saveFile">提交</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
// 作品素材重命名
export default {
  props: {
    formInfos: {
      type: Object,
      default() {
        return {};
      },
    },
    flag: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    let names = this.flag
      ? this.formInfos.name.split(".")
      : this.formInfos.alias.split(".");
    this.formInfo = {
      ...this.formInfos,
      firstName: names[0],
      lastName: names[1],
    };
  },
  watch: {
    formInfos: {
      handler(val) {
        this.formInfo = { ...this.formInfos };
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      formInfo: {},
      rules: {
        firstName: [
          { required: true, message: "作品名称不可为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    flash(){},
    saveFile() {
      if (!this.formInfo.firstName.trim()) {
        this.$message.warning("名称不可为空字符串");
        return;
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$emit("renameFile", this.formInfo);
        } else {
          return false;
        }
      });
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>