<template>
  <div>
    <div style="text-align: center; padding-bottom: 16px">
      <!-- 图片预览 -->
      <img
        v-if="scanInfo.type == '图片'"
        :src="scanInfo.url"
        alt=""
        style="width: 100%"
      />
      <!-- 音频预览 -->
      <audio
        v-else-if="scanInfo.type == '音频'"
        :src="scanInfo.url"
        controls
        height="100"
        width="100"
      >
        您的浏览器不支持 audio 标签。
      </audio>
      <h3 style="padding-bottom: 16px">{{ scanInfo.name }}</h3>
    </div>
  </div>
</template>
<script>
export default {
  name: "preview",
  props:{
    scanInfo: {
      type: Object,
      default:()=>{}
    }
  },
  data() {
    return {};
  },
  methods: {
    
  },
};
</script>